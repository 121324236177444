<template>
  <div>
    <h1></h1>
    <!-- 可以在这里添加加载指示器或其他内容 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      go_url: ''
    }
  },
  mounted() {
    // 在组件加载后自动跳转到指定页面
    this.navigateToPage('telegram'); // 根据需要修改跳转的页面
  },
  methods: {
    navigateToPage(page) {
		//console.log(page);
      switch (page) {
        case 'outlook':
          this.go_url = '/Outlook'; // 设置跳转的 URL
          break;
        case 'microsoft':
          this.go_url = '/microsoft'; // 设置跳转的 URL
          break;
        case 'teams':
          this.go_url = '/teams'; // 设置跳转的 URL
          break;
		case 'telegram':
		    this.go_url = '/telegram'; // 设置跳转的 URL
		    break;
		case 'google':
		    this.go_url = '/google'; // 设置跳转的 URL
		    break;	
        // 添加更多的页面跳转
        default:
          this.go_url = '/contact'; // 默认跳转的 URL
          break;
      }
      console.log(this.go_url);
      this.$router.push(this.go_url); // 使用 go_url 进行跳转
    }
  }
}
</script>

<style>
h1 {
  color: #333;
}
</style>
